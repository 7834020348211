import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DmrResponse } from '../../models/dmr.model';
import { HelvetiaVariant } from '../../models/helvetia.model';

import { LibUtilityService } from '../../utils';
import { PriceTagInfo } from 'projects/shared/src/public-api';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'lib-helvetia-price-card',
  templateUrl: './helvetia-price-card.component.html',
  styleUrls: ['./helvetia-price-card.component.scss'],
  animations: [
    // Animation for showing and hiding the dropdown menu
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(100)),
    ]),
  ],
})
export class HelvetiaPriceCardComponent {
  @Output() pickHelvetiaEvent = new EventEmitter<boolean>();
  @Output() getPriceTagEvent = new EventEmitter<PriceTagInfo>();
  @Output() downloadPriceTagEvent = new EventEmitter<PriceTagInfo>();
  @Output() setSelectedHelvetiaVariantEvent =
    new EventEmitter<HelvetiaVariant>();
  @Output() setYearlyAndMonthlyPriceEvent = new EventEmitter();

  @Input() errorIngetInsurancePrices: boolean = false;
  @Input() errorInHelvetiaPrice: boolean = false;
  @Input() allHaveTogglePricetag: boolean = false;
  @Input() showYearlyPrice: boolean = false;
  @Input() helvetiaMonthlyPrice: number = 0;
  @Input() helvetiaYearlyPrice: number = 0;
  @Input() helvetiaVariants: HelvetiaVariant[] = [];
  @Input() selectedHelvetiaVariant: HelvetiaVariant = {} as HelvetiaVariant;
  @Input() vehicleFromDmr: DmrResponse = {} as DmrResponse;

  helvetiaExcessTab: { name: string; value: string } = {
    name: '12.000 DKK',
    value: '12.000',
  };

  openHelvetiaPricetag: boolean = false;
  tabOptions = [
    { name: '7.000 DKK', value: '7.000' },
    { name: '12.000 DKK', value: '12.000' },
    { name: '15.000 DKK', value: '15.000' },
  ];

  constructor(private libUtilityService: LibUtilityService) {}

  onInputChange(event: any) {
    const stepIndex = event.target.value;
    this.helvetiaExcessTab = this.tabOptions[stepIndex];
    this.updatehelvetiaExcessTab();
  }

  updatehelvetiaExcessTab() {
    let variant = '';

    if (this.helvetiaExcessTab.value === '7.000') {
      variant = 'VARIANT_GOLD';
    } else if (this.helvetiaExcessTab.value === '12.000') {
      variant = 'VARIANT_SILVER';
    } else if (this.helvetiaExcessTab.value === '15.000') {
      variant = 'VARIANT_BRONZE';
    }

    const selectedVariant = this.helvetiaVariants.find(
      (v) => v.variant === variant
    );

    if (selectedVariant) {
      this.selectedHelvetiaVariant = selectedVariant;
      this.setSelectedHelvetiaVariantEvent.emit(selectedVariant);
      this.setYearlyAndMonthlyPriceEvent.emit();
    }
  }

  pickHelvetiaPrice(goToNextStep: boolean) {
    this.pickHelvetiaEvent.emit(goToNextStep);
  }

  calculateTabIndex(): number {
    if (this.selectedHelvetiaVariant.variant == 'VARIANT_GOLD') {
      return 0;
    } else if (this.selectedHelvetiaVariant.variant == 'VARIANT_SILVER') {
      return 1;
    } else if (this.selectedHelvetiaVariant.variant == 'VARIANT_BRONZE') {
      return 2;
    }
    return 0;
  }

  getHelvetiaPriceTag() {
    this.openHelvetiaPricetag = false;
    const helvetiaExcessPrice = this.helvetiaExcessTab.value.replace('.', '');

    const priceTagInfo: PriceTagInfo = {
      insuranceCompanyId: 2,
      vehicleMake: this.vehicleFromDmr.make,
      vehicleModel: this.vehicleFromDmr.model,
      monthlyPrice: +this.libUtilityService.getHelvetiaPrice(
        this.selectedHelvetiaVariant.coverages,
        'monthly'
      ),
      yearlyPrice: +this.libUtilityService.getHelvetiaPrice(
        this.selectedHelvetiaVariant.coverages,
        'yearly'
      ),
      excessPrice: +helvetiaExcessPrice,
    };

    this.getPriceTagEvent.emit(priceTagInfo);
  }

  downloadHelvetiaPriceTag() {
    this.openHelvetiaPricetag = false;
    const helvetiaExcessPrice = this.helvetiaExcessTab.value.replace('.', '');

    const priceTagInfo: PriceTagInfo = {
      insuranceCompanyId: 2,
      vehicleMake: this.vehicleFromDmr.make,
      vehicleModel: this.vehicleFromDmr.model,
      monthlyPrice: +this.libUtilityService.getHelvetiaPrice(
        this.selectedHelvetiaVariant.coverages,
        'monthly'
      ),
      yearlyPrice: +this.libUtilityService.getHelvetiaPrice(
        this.selectedHelvetiaVariant.coverages,
        'yearly'
      ),
      excessPrice: +helvetiaExcessPrice,
    };

    this.downloadPriceTagEvent.emit(priceTagInfo);
    /* this.utilityService.downloadPriceTag(
      priceTagInfo,
      'helvetia-prisskilt.pdf'
    );*/
  }
}
